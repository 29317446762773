@import "fonts";
@import "spacing";
@import "pallet";
@import "sizes";
$sizes: "xs", "sm", "md";

// hide class
@each $size in $sizes {
    .hide-#{$size} {
        @if $size == "xs" {
            @media (max-width: 401px) {
                display: none !important;
            }
        } @else if $size == "sm" {
            @media (max-width: 601px) {
                display: none !important;
            }
        } @else if $size == "md" {
            @media (max-width: 769px) {
                display: none !important;
            }
        }
    }
}
// rupee symbol
.rupee {
    font-family: "Roboto";
}

// table

.tableWrapper {
    td {
        height: 56px;
    }
    th {
        height: 56px;
        background-color: $table_header_bg;
    }
    tbody > :nth-child(even) {
        background: $table_cell_bg;
    }
}
.table-top-row {
    display: flex;
    min-height: 47px;
    align-items: stretch;
    flex-wrap: wrap;
    > .table-top-row_right > *:first-child {
        margin-left: auto;
    }
    > div {
        display: flex;
        column-gap: 1em;
        // 576px
        @media (max-width: 576px) {
            flex: 1;
            justify-content: space-between;
        }
    }
}

.global-btns-container {
    min-height: 47px;
    border-radius: 8px;
    box-shadow: none !important;
    display: flex;
    // gap: 1em;
    button {
        // background-color: $primary_button !important;
        width: 9em;
        height: 47px !important;
        border-radius: 8px !important;
        > div {
            color: $color_IMT_White !important;
        }
    }
}
.customSearchIcon {
    // mobile and tablet
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        background-color: $color_IMT_White;
        border-radius: 10px;
        max-width: 54px;
        height: 47px;
        width: 47px;
    }
}
.customSearchPopover {
    .MuiPaper-root {
        border-radius: 10px;
        box-shadow: none;
        margin-top: -1px;
        height: 47px;
        // 768px
        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
}
.customSearch {
    display: flex;
    align-items: center;
    gap: 0;
    width: 20em;
    &.withDropDown {
        width: 25em;
    }
    overflow: unset;
    background: $color_IMT_White;
    border-radius: 10px;
    height: 47px;
    > div {
        flex: 1;
    }
    @media (max-width: 1024px) {
        // overflow: hidden;
        padding: 0;
        &.outer {
            display: none;
        }
    }
    .customSearch_input {
        display: flex;
        input {
            border: none;
            outline: none;
            color: $base_font;
            font-size: 14px;
        }
    }
    .customSearch_select {
        min-width: 30%;
        color: $base_font;
    }
    @media (max-width: 767px) {
        // width: 50%;
        width: 80vw;
        // position: absolute;
        max-width: 80vw;
    }
}
.customSearchPopoverMembers {
    .MuiPaper-root {
        overflow: unset !important;
    }
}

.filter {
    border-radius: 10px !important;
    height: 47px;
    overflow: hidden;
    .tab {
        font-family: "Poppins", sans-serif !important;
    }
    // small screen
    .filterSmall {
        height: 47px;
        display: none;
        width: 100%;
        margin-top: 0 !important;
    }
    @media (max-width: 767px) {
        min-width: 10em;
        width: 15em;
        overflow: visible;
        .filterSmall {
            display: flex;
        }
        .filterNormal {
            display: none;
        }
    }

    .filterSmall {
        height: 100%;
    }

    $height: 47px;
    .custom-dd {
        .dd-list-wrapper {
            min-height: $height;
        }
        .dd-selected {
            min-height: $height;
        }
        .dd-list {
            > div {
                min-height: $height;
            }
        }
        .dd-one-item {
            min-height: $height;
        }
    }
}
.extras {
    display: flex;
    background: $color_IMT_White;
    gap: 1em;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 10px;
    border: none;
    outline: none !important;
    margin: 0 !important;
    span {
        color: $active !important;
        font-size: 12px !important;
    }
    &:active {
        outline: none;
        background: $color_S100;
    }
}

// borderless input with white background
// input{
//     background: $color_IMT_White;
//     border: none;
//     border-radius: 10px;
//     color: $common;
// }

.buttonPrimary {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 48px;
    background-color: $primary_button;
    border-radius: 8px;
    color: $color_IMT_White;
    border: none;

    &:disabled {
        opacity: 0.5;
    }
}

.buttonWhite {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 48px;
    background-color: $color_IMT_White_button;
    border-radius: 8px;
    color: $primary;
    border: none;
}

.buttonOutlined {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 48px;
    background-color: $color_IMT_White_button;
    border-radius: 8px;
    color: $primary;
    border: 1px solid $primary;
}

.buttonDashed {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 48px;
    background-color: $color_IMT_White_button;
    border-radius: 8px;
    color: $primary;
    border: 1px dashed $primary;
}
.buttonRed {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 48px;
    background-color: $color_IMT_White;
    border-radius: 8px;
    color: $negative;
    border: none;
    box-shadow: 0px 0px 5px 0px $color_N50;
    &:hover {
        background-color: $negative;
        color: $color_IMT_White;
    }
}

.no-margin {
    margin: 0 !important;
}

.buttonTransparent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 48px;
    background-color: transparent;
    border-radius: 8px;
    color: $primary;
    border: 1px solid $primary;
}

.buttonTranlucent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 48px;
    background-color: $secondary_button;
    border-radius: 8px;
    color: $primary;
    border: 1px solid $primary;
}

.twoColumn {
    display: flex;
    gap: 112px;
    .twoColumn_left {
        flex: 1;
    }
    .twoColumn_right {
        min-width: 337px;
        max-width: 337px;
        @media (max-width: 1024px) {
            max-width: 1000px;
        }
    }

    .twoColumn_rightS {
        min-width: 275px;
        max-width: 275px;
        @media (max-width: 1024px) {
            max-width: 1000px;
        }
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 40px;
    }
}
.filterDark {
    filter: invert(26%) sepia(0%) saturate(0%) hue-rotate(359deg)
        brightness(95%) contrast(88%);
}

.filterPrimary {
    filter: $filter_primary;
}

.iconSize_small {
    height: 15px !important;
    width: 15px !important;
}

.iconSize {
    height: 20px !important;
    width: 20px !important;
}
.iconSize1 {
    height: 30px !important;
    width: 30px !important;
}

.displayFlex {
    display: flex;
}

.roundCornerTop {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.roundCornerBottom {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.pointer {
    cursor: pointer;
}

.hoverable {
    position: relative;
    .hoverableTarget {
        position: absolute;
        top: 1em;
        left: 1em;
        display: none;
        background: $color_IMT_White;
        padding: 0.5em;
        box-shadow: 0px 0px 5px 0px $color_N50;
        &.hoverableTarget_bottom {
            top: 1em;
            left: -7em;
        }
    }
    &:hover {
        .hoverableTarget {
            display: flex;
            min-width: 15em;
            z-index: 10;
        }
    }
}

.logo_container {
    display: flex;
    margin-left: auto;
    align-items: end;
    .logo-image-container {
        max-height: 44px;
        img {
            margin-top: auto;
        }
    }
    .logo-image-container.increasedHeight {
        max-height: 60px;
    }
    // .partner-logo{
    // }
    > :first-child {
        padding-right: 10px;
    }
    > :last-child {
        border-left: solid $color_N400 1px;
        // >.logo-image-container{
        // }
        h3 {
            color: $color_N600;
        }
        // > * {
        //     padding-left: 10px;
        // }
    }
}

.topSeperator {
    margin-top: 30px;
    margin-bottom: 30px;
}
.topDropdown {
    width: 100px;
}
.noWrap {
    word-wrap: none;
}

.leftContainer {
    .cardCustom {
        $width: calc((100% - 2em) / 2);
        $widthS: 100%;

        width: $width;
        @media (max-width: 520px) {
            width: $widthS;
        }
    }
}

.rightContainer {
    .cardCustom {
        $widthR: 350px;
        $widthRS: 100%;
        width: $widthR;
        @media (max-width: 520px) {
            width: $widthRS;
        }
    }
}

.shadow {
    box-shadow: 0px 0px 5px 0px $color_N50;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.border_primary {
    border: 2px solid $primary;
    border-radius: 5px;
}

.border{
    border: 2px solid  #EAEAEF;
}

.whiteBackground {
    background-color: $color_IMT_White;
}

.centerOfWindow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mobileOnly {
    display: none !important;
    @media (max-width: 768px) {
        display: block !important;
    }
}

.surface2 {
    box-shadow: 0px 2px 12px 0px rgba(39, 57, 147, 0.08);
}

.surface1 {
    box-shadow: 0px 2px 6px 0px rgba(39, 57, 147, 0.06);
}

.surface3 {
    box-shadow: 0px 8px 28px 0px rgba(39, 57, 147, 0.08);
}

.surface4 {
    box-shadow: 0px 14px 42px 0px rgba(20, 20, 43, 0.14);
}

.surface5 {
    box-shadow: 0px 24px 65px 0px rgba(20, 20, 43, 0.16);
}

.surface6 {
    box-shadow: 0px 32px 72px 0px rgba(0, 0, 0, 0.24);
}

.button__elevation_color1 {
    box-shadow: 0px 4px 10px 0px rgba(74, 58, 255, 0.06);
}
.button__elevation_color2 {
    box-shadow: 0px 6px 20px 0px rgba(74, 58, 255, 0.08);
}

.button__elevation_color3 {
    box-shadow: 0px 10px 28px 0px rgba(74, 58, 255, 0.12);
}

.button__elevation_white1 {
    box-shadow: 0px 4px 10px 0px rgba(20, 20, 43, 0.04);
}
.button__elevation_white2 {
    box-shadow: 0px 6px 20px 0px rgba(20, 20, 43, 0.06);
}
.button__elevation_white3 {
    box-shadow: 0px 10px 28px 0px rgba(20, 20, 43, 0.1);
}









.body-container_v2{
    // width: calc(100% - 189px);
    width: 100%;
    overflow-y: scroll;
    height: calc(100vh - 88px);
 
    
    @media (min-width: 1024px) {
        >*{
            padding-right: 189px;
        }
    }
    @media (max-width: 1024px) {
        padding-bottom: 88px;
        padding-left: 20px;
        padding-right: 20px;
    }

    // if enrollmentEnabled class is added present padding is removed
    &.enrollmentEnabled{
        @media (min-width: 1024px) {
            >*{
                padding-right: 0;
            }
        }
        @media (max-width: 1024px) {
            padding-bottom: 88px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    input[type="checkbox"] {
        accent-color: var(--primary);
    }
}
@import "../../assets/scss/partials/pallet";

.body-container_v2 {
    .claims_header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .top-metadata-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0;
            margin-top: 0;
            h1 {
                margin-bottom: 0;
            }
            > .custom-dd {
                margin-left: 16px;
                margin-bottom: 0;
                width: 20ch;
            }
        }

        &__v2 {
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .topDropdown{
        background-color: white;
    }

    .claims_steps_dropdown {
        height: 52px !important;
        border-radius: 100px;
        overflow: hidden;
        > * {
            height: 100% !important;
        }
        * {
            font-size: 14px;
            font-weight: 600;
        }
        svg {
            margin-right: 16px;
            font-size: 20px;
        }
        border: none;
        fieldset {
            border: none;
        }
        @media (max-width: 768px) {
            width: 100%;
            background: var(--Blacks-200, #f8f7fa);
        }
    }

    .claims_container {
        display: flex;
        gap: 112px;
        // 768px

        &__v2 {
            width: 100%;
        }

        @media (max-width: 768px) {
            gap: 28px;
        }
        justify-content: space-between;
        .claims_container_left {
            flex: 1;
            gap: 1em;
            line-height: 2em;
        }
        .claims_container_right {
            min-width: 337px;
            max-width: 337px;
        }
        .claims_container_right_customerCare {
            color: $color_IMT_White;
            h2 {
                color: $color_IMT_White;
            }
            display: flex;
            flex-direction: column;
            border-radius: 16px;
            justify-content: space-between;
            background: $cardGradient;
            padding: 16px;
            width: 100%;
            max-width: 380px;

            > div {
                display: flex;
                gap: 2em;
                p {
                    color: $color_IMT_White;
                    margin-bottom: 0.5em;
                }
            }
            img {
                height: 35px;
            }
            button {
                padding: 0.6em 3em;
                border: none;
                border-radius: 8px;
                width: max-content;
                background: $color_IMT_White;
                color: $primary;
                height: 40px;
            }
        }

        // 1024px
        @media (max-width: 1024px) {
            flex-direction: column;
            .claims_container_left {
                flex: 1;
                gap: 2em;
            }
            .claims_container_right {
                min-width: 0;
                display: flex;
                gap: 20px;
            }
        }
    }

    .claims_steps {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .claims_step {
            display: flex;
            font-size: 12px;
            p {
                font-size: 12px;
                margin-bottom: 0;
            }
        }
        .claims_step_numbers {
            color: $color_IMT_White;
            border-radius: 100%;
            background-color: $color_N900;
            height: 24px;
            width: 24px;
            flex-shrink: 0;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
        }
        ol,
        ul {
            margin-left: 2em;

            li {
                > p {
                    color: $hyperLink;
                    cursor: pointer;
                    font-size: 12px;
                    margin-bottom: 0;
                }
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
        #steps-html {
            p {
                color: $headings_font;
                font-size: 14px;
                margin-bottom: 0;
            }
            a {
                color: $hyperLink;
            }
        }
    }

    .claims_graph {
        display: flex;
        margin-bottom: 15px;
        flex-direction: row;
        gap: 1em;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        > :first-child {
            flex: 10;
            width: 100%;
            min-height: 530px;
        }
        .claims_graph_values {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: auto;
            gap: 1em;
            @media (max-width: 768px) {
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;
            }
            > * {
                flex: 1;
                max-width: 200px;
                padding: 0 1em;
            }
        }
        .claims_graph_values_item {
            background-color: $color_IMT_White;
            width: 100%;
            border-radius: 8px;
            padding-top: 1em;
            padding-bottom: 1em;
            @media (max-width: 768px) {
                width: 120px;
            }
        }
        .claims_line_chart {
            background-color: $color_IMT_White;
            margin-right: 15px;
            border-radius: 12px;
            @media (max-width: 768px) {
                margin-right: 10px;
            }
            canvas {
                padding: 12px;
            }
        }
    }

    .uploadClaim {
        .uploadClaim_upload {
            width: 100%;
            align-items: flex-end;
            flex-direction: column;
            button {
                margin-left: 0;
            }
        }
        .uploadClaim_template {
            margin-top: 0rem;
            h3 {
                margin-top: 0.8rem;
            }
        }
        .claimMapperContainer {
            td {
                min-width: 250px !important;
                max-width: 250px !important;
            }
        }
    }

    .actionCard {
        position: relative;
        cursor: pointer;
        // flex: 1;
        min-height: 60px;
        display: flex;
        padding: 16px;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        background-color: $color_IMT_White;
        border-radius: 24px;
        border: 1px solid $color_N200;
        .actionCard_icon {
            flex: 0 0 36px;
            width: 36px;
            align-items: center;
            height: 36px;
            display: flex;
            border: 1px solid $primary;
            border-radius: 50%;
            justify-content: center;
            img {
                height: 14px;
                width: auto;
            }
            &.right-icon {
                flex: 0 0 14px;
                height: 14px;
                width: 14px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .actionCard_thumb {
            flex: 0 0 40px;
            position: relative;
            width: 40px;
            height: 40px;
            background-color: $color_N100;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            img {
                max-width: 27px;
                max-height: 27px;
            }
        }
        .actionCard_content {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-inline: 20px;
        }
        &.button__know_more {
            box-shadow: none;
            width: 100%;
            border-radius: 8px;
            border: 1px solid $color_N200;
            padding: 16px;
            .actionCard_thumb {
                flex: 0 0 40px;
                display: grid;
                place-items: center;
                img {
                    width: 40px;
                    height: 40px;
                }
            }
            @media (max-width: 430px) {
                width: 100%;
            }
        }
    }

    .mobileOnlyHelpButton {
        padding: 6px 12px;
        border: 1px solid $color_IMT_White;
        border-radius: 5px;
        color: $color_N800;
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.12px;
        display: none;
        align-items: center;
        gap: 8px;

        &__v2 {
            display: flex;
        }

        @media (max-width: 1024px) {
            display: flex;
            padding: 3px 6px;
        }
        @media (max-width: 768px) {
            &__admin {
                display: flex;
            }
        }
        @media (max-width: 375px) {
            gap: 0;
            font-size: 0 !important;
        }
    }

    .popup_cancelIcon {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .claims-navbar {
        display: flex;
        align-items: center;
        background: $color_IMT_Black;
        justify-content: space-between;
    }
}

.ClaimPopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 337px;
    background-color: $color_IMT_White;
    padding: 2em;
    border-radius: 0.8em;
    display: flex;
    flex-direction: column;
    gap: 1em;

    &.intimatePopup {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        padding-top: 4em;
        p {
            color: $headings_font;
            margin-bottom: 0.5em;
            font-size: 14px;
        }
        strong {
            color: $color_IMT_Black;
        }
        h1 {
            color: $color_IMT_Black;
        }
        .intimatePopup_icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &.uploadHomePopup {
        // width: 380px;

        padding-top: 5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
            color: $color_IMT_Black;
            font-size: 14px;
            text-align: center;
            font-weight: 500;
        }
    }

    &.customerCarePopup {
        padding: 0;
        background-color: transparent;
    }

    &.uploadPopup {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: $color_IMT_Black;
        .uploadPopup_banner {
            display: grid;
            place-items: center;
            padding: 2em;
            img {
                max-width: 140px;
                max-height: 140px;
            }
        }
        .uploadPopup_content {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            h2 {
                color: $color_IMT_White;
            }
            padding: 2em;
            ol {
                margin-left: 2em;
            }
        }
    }
}
